export const LOGIN = '/system/user/login';
export const LOGOUT = '/system/user/logout/';
export const ADD_USER = '/system/user/add';
export const DELETE_USER = '/system/user/delete/';
export const MODIFY_USER = '/system/user/modify';
export const HAS_USERNAME = '/system/user/has_username/';
export const GET_ALL_USER = '/system/user/all';

export const GET_ALL_COMPANY = '/system/company/all';
// export const GET_ALL_SERVICE_DEP_IN_COMPANY = '/system/company/all';

export const ADD_MACHINE = '/system/machine/add';
export const DELETE_MACHINE = '/system/machine/delete/';
export const MODIFY_MACHINE = '/system/machine/modify';
export const GET_ALL_MACHINE = '/system/machine/all';
export const GET_ALL_MACHINE_IN_COMPANY = '/system/machine/company/';
export const GET_ALL_MACHINE_IN_SERVICE_DEP = '/system/machine/service_dep/';
export const GET_ALL_MACHINE_IN_CENTER_STATION = '/system/machine/center_station/';
export const GET_ALL_MACHINE_IN_STATION = '/system/machine/station/';
export const GET_MACHINE_BY_ID = '/system/machine/';

export default {
  LOGIN, LOGOUT, ADD_USER, DELETE_USER, MODIFY_USER, HAS_USERNAME, GET_ALL_USER,

  GET_ALL_COMPANY,
  ADD_MACHINE, DELETE_MACHINE, MODIFY_MACHINE, GET_ALL_MACHINE, GET_ALL_MACHINE_IN_COMPANY, GET_ALL_MACHINE_IN_SERVICE_DEP, GET_ALL_MACHINE_IN_CENTER_STATION, GET_ALL_MACHINE_IN_STATION, GET_MACHINE_BY_ID,
}

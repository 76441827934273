<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  input[type=file] {
    display: none !important;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2D3C50;
}
  .bg{
    color: #FFFFFF;
  }
  .bgb{
    color: black;
  }
a {
  color: #2D3C50;
  cursor: pointer;
}
a:link { }
a:visited { }
a:hover {
  color: #F09600;
}
a:active {
  color: #FDA118;
}

a.faa {
  text-underline: none;
  color: #2D3C50;
  cursor: pointer;
}
a:link.faa { }
a:visited.faa { }
a:hover.faa {
  color: #4D596B;
}
a:active.faa {
  color: #6E7786;
}

h3 {
  margin: 0 0;
}

td {
  height: 35px;
  line-height: 35px;
  padding: 8px 0 !important;
}

.blank5 {
  width: 100%;
  height: 5px;
}

.blank10 {
  width: 100%;
  height: 10px;
}

.blank15 {
  width: 100%;
  height: 15px;
}

.blank20 {
  width: 100%;
  height: 20px;
}

.clearPadding {
  padding: 0 0;
}

.bg-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.transparent {
  /*filter:alpha(Opacity=0);*/
  /*-moz-opacity:0;*/
  /*opacity: 0;*/
}

.text-tip {
  font-size: 16px;
  line-height: 36px;
}

.login-box {
  max-height: 8000px;
  min-height: 240px;
  /*水平居中*/
  margin: 0 auto 0;
  /*position: relative;*/
  /*top: 50%; !*偏移*!*/
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
}

.frame {
  margin: 20px;
  width: calc(100vw - 110px);
}
.fullHeight {
  height: calc(100vh - 95px);
}
.fullHeightClearTop {
  margin: 0 20px 0 20px;
  height: calc(100vh - 100px);
}
.pageHeader {
  width: 100%;
  padding: 20px 20px 20px 20px;
}

.reception-header {
  width: 100%;
  padding: 0 0 20px 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.reception-header h3 {
  font-size: 20px;
}

.btn-full {
  width: 100% !important;
  min-width: 100px;
}

.mxmTabs {
  border: none;
}
.mxmTabs .el-tab-pane {
  border: 1px solid #3CBEBE;
  border-top-width: 4px;
  margin: -15px -15px -15px -15px;
  width: calc(100% + 30px);
  height: calc(100vh - 133px);
  overflow: hidden;
}
.mxmTabs .scrollable {
  overflow-y: auto;
}
.mxmTabs .el-tabs__header {
  background-color: transparent !important;
}
.mxmTabs .is-active {
  background-color: #2D3C50 !important;
  color: whitesmoke !important;
}

.tableInWorkbench {
  margin: 20px 0 auto 0;
  width: 100%;
  height: calc(100vh - 180px);
}

.fullInWorkbench {
  margin: 0 0 auto 0;
  width: 100%;
  height: calc(100vh - 120px);
}
.with1lineInWorkbench {
  margin: 20px 0 0 0;
  width: 100%;
  height: calc(100vh - 180px);
}
.with2lineInWorkbench {
  margin: 20px 0 0 0;
  width: 100%;
  height: calc(100vh - 240px);
}

.formContainer {
  margin: 0 auto;
  max-width: 1150px;
  padding: 0 20px;
}
.mxmForm>div {
  line-height: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
}
.mxmForm .el-select {
  width: 100%;
}
.mxmForm .el-input {
  width: 100%;
}

.number-full {
  width: 100%;
  min-width: 120px;
}

.top-data {
  border: 1px solid #E0E0E0;
  background-color: whitesmoke;
  text-align: center;
}
.top-data h3 {
  color: #F09600;
  font-size:2.6vw;
  line-height: 100px;
  font-weight: 600;
}
.top-data h5 {
  color: #2D3C50;
  height: 30px;
  vertical-align: top;
}
.chart-title {
  font-size: 20px;
  text-align: center;
}
.chart-pc {
  height: calc(100vh - 370px);
  width: 100%
}

.el-dialog {
  border: 1px solid #3CBEBE;
  border-top-width: 4px;
  min-width: 400px;
}
.el-dialog__header {
  background-color: #2D3C50;
  height: 58px;
}
.el-dialog__title {
  color: whitesmoke;
}
.el-dialog__headerbtn {
  margin-top: -3px;
}

.el-textarea__inner {
  min-height: 40px !important;
}

.receptionTable {
  width: 100%;
  height: calc(100vh - 222px);
}

#club_select .el-cascader-menu {
  width: 100%;
}
#select_club_box .el-cascader-menu__wrap {
  height: 280px;
}
</style>

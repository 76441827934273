export const localStorageUcm = 'ucm'
export const localStorageUserToken = 'userToken'
export const localStorageUserInfo = 'userInfo'
export const localStorageClubInfo = 'clubInfo'
export const localStorageAllUcms = 'allUcms'
export const localStorageAllScenarios = 'allScenarios'
export const localStorageAllChamberVouchers = 'allChamberVouchers'

export default {
  localStorageUcm,
  localStorageUserToken,
  localStorageUserInfo,
  localStorageClubInfo,
  localStorageAllUcms,
  localStorageAllScenarios,
  localStorageAllChamberVouchers
}

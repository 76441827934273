export const datetimeFormat = {
  FULL_CHS: 'YYYY年M月D日(周W) HH:mm:ss',
  YEAR_MINUTE_CHS: 'YYYY年M月D日(周W) HH:mm',
  MONTH_MINUTE_CHS: 'M月D日(周W) HH:mm',
  MONTH_SECOND_CHS: 'M月D日(周W) HH:mm:ss',
  FULL: 'YYYY-M-D(周W) HH:mm:ss',
  YEAR_MINUTE: 'YYYY-M-D(周W) HH:mm',
  MONTH_MINUTE: 'M-D(周W) HH:mm',
  MONTH_SECOND: 'M-D(周W) HH:mm:ss',
  FULL_NO_WEEK: 'YYYY-M-D HH:mm:ss',
  YEAR_MINUTE_NO_WEEK: 'YYYY-M-D HH:mm',
  MONTH_MINUTE_NO_WEEK: 'M-D HH:mm',
  MONTH_SECOND_NO_WEEK: 'M-D HH:mm:ss',
  FULL_DATE_CHS: 'YYYY年M月D日(周W)',
  FULL_DATE_CHS_NO_WEEK: 'YYYY年M月D日',
  FULL_DATE: 'YYYY-M-D(周W)',
  FULL_DATE_NO_WEEK: 'YYYY-M-D',
  TIME_WITH_SECOND: 'HH:mm:ss',
  TIME_NO_SECOND: 'HH:mm'
}

export const modeOfPayment = {
  CASH: '现金',
  WECHAT: '微信',
  ALIPAY: '支付宝',
  MEITUAN: '美团收单',
  CASH_REGISTER: '收款机',
  OTHER: '其它'
}

export const orderUsage = {
  CHAMBER_RETAIL: '密室',
  MERCHANDISE: '商品',
  MEMBERSHIP_RECHARGE: '会员充值'
}

export const chamberRetailStatus = {
  IN_QUEUE: '排队中',
  CANCEL: '取消',
  TERMINATE: '终止',
  MONEY_BACK: '退款'
}

export default {
  datetimeFormat,
  modeOfPayment,
  orderUsage,
  chamberRetailStatus
}

<template>
  <el-container>
    <el-main class="clearPadding">
      <div class="content">
        <router-view id="main" />
      </div>
    </el-main>
    <el-footer height="50" class="clearPadding">
      <el-row>
        <el-col id="footer" :span=24>
          <div class="container">© 2021 辽宁省华顺热力集团抚顺新北方供热有限公司</div>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
import clientUtils from '../../js/clientUtils'
import 'element-ui/lib/theme-chalk/display.css'

export default {
  name: 'Simple',
  data: function () {
    return {
      title: '',
      welcome: '',
      logoutText: '',
      userInfo: {
        nickname: '未知用户'
      }
    }
  },
  methods: {
    logout: function () {
      clientUtils.logout();
      this.$router.push('/login');
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  #footer {
    height: 50px;
    background-color: #2D3C50;
    color: #dddddd;
    vertical-align: center;
    line-height: 50px;
  }
  .content {
    margin: 0;
    padding: 0;
    height: calc(100vh - 50px);
    overflow: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
  }
  @media screen and ( min-width: 992px ) {
    /*.in-simple {*/
    /*  min-height: calc(100vh - 280px);*/
    /*}*/
  }
</style>

export const SYSTEM_ERA = 1990
export const HOURS_OF_DAY_OFFSET_FOR_RETAIL = 7
export const enableLocalStorageEncryption = false
export const SMS_RESEND_SECONDS = 60
export const CHAMBER_RETAIL_AUTO_REFRESH_SECONDS = 30
export const COMMON_REFRESH_INTERVAL_MS = 500

export function todayStart () {
    let now = new Date()
    if (now.getHours() < HOURS_OF_DAY_OFFSET_FOR_RETAIL) {
        now = new Date(now.getTime() - 24 * 60 * 60 * 24)
    }
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    return now
}

export function todayEnd () {
    return new Date(todayStart().getTime() + 24 * 60 * 60 * 24)
}

export default {
    SYSTEM_ERA,
    HOURS_OF_DAY_OFFSET_FOR_RETAIL,
    enableLocalStorageEncryption,
    SMS_RESEND_SECONDS,
    CHAMBER_RETAIL_AUTO_REFRESH_SECONDS,
    COMMON_REFRESH_INTERVAL_MS,

    todayStart,
    todayEnd
}
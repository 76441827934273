import Vue from 'vue'
import VueRouter from 'vue-router'

import Workbench from '../components/common/Workbench'
import Simple from '../components/common/Simple'

Vue.use(VueRouter);

const routes = [
  // 根路由节点，跳转到网站首页
  {path: '/', redirect: '/default'},
  // 简单页面
  {
    path: '/',
    component: Simple,
    children: [
      // 登录
      {path: '/login', component: () => import('../components/user/Login')}
    ]
  },
  // 工作台页面
  {
    path: '/',
    component: Workbench,
    children: [
      // 工作台首页
      {path: 'default', component: () => import('../components/common/Desktop')},
      // 夏季工作台
      {path: 'summer', component: () => import('../components/common/Desktop')},
      // 热网实时数据
      {path: 'realtime', component: () => import('../components/ihs/RealTime')},
      // 热网历史数据
      {path: 'history', component: () => import('../components/ihs/RealTime')},
      // 岗位构成
      {path: 'duty', component: () => import('../components/system/StaffManagement')},
      // 通讯录
      {path: 'contact', component: () => import('../components/system/StaffManagement')},
      // 责任成本（扩建）
      {path: 'cost', component: () => import('../components/system/primecost')},
      //责任成本（维修）
      {path: 'costone', component: () => import('../components/system/primecostone')},
      // 改造工程
      {path: 'reconstruction', component: () => import('../components/system/StaffManagement')},
      // 扩建工程
      {path: 'expansion', component: () => import('../components/system/StaffManagement')},
      // 物资管理
      {path: 'material', component: () => import('../components/system/StaffManagement')},
      // 机组管理
      {path: 'machines', component: () => import('../components/system/MachineManagement')},
      // OPC变量名设置
      {path: 'opc', component: () => import('../components/system/StaffManagement')},
      // 用户管理
      {path: 'user', component: () => import('../components/system/StaffManagement')},
      // 物料管理
      {path: 'material', component: () => import('../components/system/StaffManagement')},
      // 数据字典管理
      {path: 'data_dic', component: () => import('../components/system/StaffManagement')},
      {path: 'test', component: () => import('../components/common/Tes')},
      // 多文件列表，根据栏目分拣参数
      // 应急预案、知识培训
      {path: 'files/:category', component: () => import('../components/common/Tes')},
      {path: 'management',component:()=>import('../components/common/Materialmanagement')},
      {path: 'otherfiles/:category', component: () => import('../components/file/otherFile')},
      {path: 'filesroot/:category', component: () => import('../components/file/fileroot')},
      {path: 'energy', component: () => import('../components/common/energy')},
      {path: 'line',name:"line", component: () => import('../components/common/linepic')},
      {path: 'phone', component: () => import('../components/ihs/Phonepic')},
      {path: 'phone', component: () => import('../components/ihs/biao')},
      {path: 'chaobiao', component: () => import('../components/ihs/Input')},
      {path: 'otc', component: () => import('../components/ihs/otc')},
      // {path: 'files/:category', component: () => import('../components/file/MultipleFileList')}
      //日报表
      {path: 'DayForm',name:"DayForm", component: () => import('../components/form/DayForm')},
      //日报表原始数据
      {path: 'DayFormInitData',name:"DayFormInitData", component: () => import('../components/form/DayFormInitData')},
      //计划表
      {path: 'Plan',name:"plan", component: () => import('../components/form/Plan')},
      //周报表
      {path: 'WeekForm',name:"WeekForm", component: () => import('../components/form/WeekForm')},
      //月报表
      {path: 'MonthForm',name:"MonthForm", component: () => import('../components/form/MonthForm')},
    ]
  },
  // 跳转
  {path: '/reception', redirect: '/reception/chamber'}
];

const router = new VueRouter({
  mode: 'hash',
  routes
});

export default router

<template>
<!--  侧边导航栏-->
  <el-container>
    <el-header height="55px" class="header"  :class="name?'bgcb':'bgc'">
      <el-row>
        <el-col :span="8" class="text-left" style="font-size: 16px;">
          <el-button class="hidden-sm-and-up" @click="showHideMenu">菜单</el-button>
          <img alt="" src="/img/logo_35.png" />
          <b> 辽宁省华顺热力集团抚顺区域管理应用平台</b>
        </el-col>
        <el-col :span="8" class="">
          <a @click="companyInfo" class="topShortCut"><i class="el-icon-office-building"></i> 公司简介</a>
          <a :href="dutyUrl" target="_blank" class="topShortCut"><i class="el-icon-notebook-1"></i> 岗位构成</a>
          <a :href="contactUrl" target="_blank" class="topShortCut"><i class="el-icon-notebook-1"></i> 通讯录</a>
          <router-link to="/filesroot/emergency" class="topShortCut"><i class="el-icon-bell"></i> 应急预案</router-link>
          <router-link to="/filesroot/how" class="topShortCut"><i class="el-icon-folder-opened"></i>知识培训</router-link>
<!--          <router-link to="/files/mouthPlan" class="topShortCut"><i class="el-icon-folder-opened"></i>月计划</router-link>-->
<!--          <router-link to="/files/weekPlan" class="topShortCut"><i class="el-icon-folder-opened"></i>周计划</router-link>-->
        </el-col>
        <el-col :span="8" class="text-right">
          欢迎您：{{ userInfo != null ? userInfo.nickname : '未知用户' }}
          <a @click="logout">退出登录</a>
        </el-col>
      </el-row>
    </el-header>
    <el-main class="main" :class="name?'bgcb':'bgc'">
      <el-menu :default-active="menuActive" :router=true :class="menuClass" :collapse="isCollapse" @select="menuSelected" >
        <el-menu-item index="/default">
          <i class="el-icon-s-platform"></i>
          <span slot="title">我的工作台</span>
        </el-menu-item>
        <el-submenu v-if="true" index="public">
          <template slot="title">
            <i class="el-icon-collection"></i>
            <span>公共知识</span>
          </template>
          <el-menu-item v-if="true" @click="companyInfo"><i class="el-icon-office-building"></i> 公司简介</el-menu-item>
          <el-menu-item v-if="true" @click="goto(dutyUrl)"><i class="el-icon-data-line"></i> 岗位构成</el-menu-item>
          <el-menu-item v-if="true"  @click="goto(contactUrl)"><i class="el-icon-notebook-1"></i> 通讯录</el-menu-item>
          <el-menu-item v-if="true" index="/filesroot/emergency"><i class="el-icon-bell"></i> 应急预案</el-menu-item>
          <el-menu-item v-if="true" index="/filesroot/how"><i class="el-icon-folder-opened"></i> 知识培训</el-menu-item>
        </el-submenu>
        <el-menu-item index="/realtime">
          <i class="el-icon-data-board"></i>
          <span slot="title">热网实时数据</span>
        </el-menu-item>
        <el-menu-item index="/line">
          <i class="el-icon-data-line"></i>
          <span slot="title">热网历史数据</span>
        </el-menu-item>

        <el-menu-item index="/DayFormInitData">
          <i class="el-icon-data-analysis"></i>
          <span slot="title">抄表数据</span>
        </el-menu-item>

<!--        <el-submenu index="system1">-->
<!--          <template slot="title">-->
<!--            <i class="el-icon-data-analysis"></i>-->
<!--            <span>能耗报表</span>-->
<!--          </template>-->
<!--          <el-menu-item index="/DayForm"><i class="el-icon-switch-button"></i>日报表</el-menu-item>-->
<!--          <el-menu-item index="/WeekForm"><i class="el-icon-switch-button"></i>周报表</el-menu-item>-->
<!--          <el-menu-item index="/MonthForm"><i class="el-icon-switch-button"></i>月报表</el-menu-item>-->
<!--        </el-submenu>-->


        <el-submenu v-if="true" index="/cost">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>预算审批</span>
          </template>
          <el-menu-item v-if="true" index="/cost"><i class="el-icon-s-finance"></i>责任成本</el-menu-item>
<!--          <el-menu-item v-if="true" index="/costone"><i class="el-icon-s-finance"></i>责任成本(维修)</el-menu-item>-->
          <el-menu-item v-if="true" index="/Plan"><i class="el-icon-s-finance"></i>年计划管理</el-menu-item>
          <el-menu-item index="/management"><i class="el-icon-setting"></i>物料管理</el-menu-item>
        </el-submenu>
        <el-submenu index="system">
          <template slot="title">
            <i class="el-icon-s-operation"></i>
            <span>系统管理</span>
          </template>
          <el-menu-item index="/machines"><i class="el-icon-switch-button"></i>机组管理</el-menu-item>
          <el-menu-item index="/user"><i class="el-icon-s-custom"></i>用户管理</el-menu-item>
<!--          <el-menu-item index="/otc"><i class="el-icon-s-custom"></i>otc变量名管理</el-menu-item>-->
        </el-submenu>

        <el-submenu index="#">
          <template slot="title">
            <i class="el-icon-folder-opened"></i>
            <span>文件管理</span>
          </template>
<!--          <el-menu-item index="/filesroot/bloc"><i class="el-icon-folder-opened"></i>集团模板</el-menu-item>-->
          <el-menu-item index="/filesroot/company"><i class="el-icon-folder-opened"></i>公司模板</el-menu-item>
          <el-menu-item index="/filesroot/emergency"><i class="el-icon-folder-opened"></i>应急预案</el-menu-item>
          <el-menu-item index="/filesroot/how"><i class="el-icon-folder-opened"></i>知识培训</el-menu-item>
          <el-menu-item index="/filesroot/personal"><i class="el-icon-folder-opened"></i>个人文件</el-menu-item>
          <el-menu-item index="/filesroot/addressBook"><i class="el-icon-folder-opened"></i>通讯录</el-menu-item>
          <el-menu-item index="/filesroot/Post"><i class="el-icon-folder-opened"></i>岗位构成</el-menu-item>
        </el-submenu>


        <el-menu-item index="/chaobiao">
          <i class="el-icon-price-tag"></i>
          <span slot="title">抄表</span>
        </el-menu-item>

      </el-menu>
      <div class="content">
        <router-view id="content" @selectMenu="selectMenu" :key="new Date().getTime() + ''"/>
      </div>
    </el-main>
    <el-footer height="25px" class="footer">© 辽宁省华顺热力集团抚顺区域</el-footer>
  </el-container>
</template>

<script>
  import clientUtils from '../../js/clientUtils'
  import {datetimeFormat} from '../../js/enums'
  import bus from "../../js/bus";
  export default {
    name: 'Workbench',
    // components: {LinButton},
    data: function () {
      return {
        menuClass: 'menu',
        isCollapse: true,
        menuActive: '/default',
        pageTitle: '',
        name:true,
        userInfo: {},
        chamberReceptionVisible: false,
        hasCashIncomePermission: false,
        dutyUrl: 'https://hsihs-fushun-file.oss-cn-zhangjiakou.aliyuncs.com/demo/%E5%9F%8E%E5%8D%97%E7%83%AD%E7%94%B5%E7%BB%84%E7%BB%87%E7%BB%93%E6%9E%84%E5%9B%BE.pdf',
        contactUrl: 'https://hsihs-fushun-file.oss-cn-zhangjiakou.aliyuncs.com/demo/%E5%9F%8E%E5%8D%97%E7%83%AD%E7%94%B5%E9%80%9A%E8%AE%AF%E5%BD%95.png',
      }
    },
    computed: {
      yesterday: {
        get() {
          const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
          return clientUtils.formatDatetime(yesterday, datetimeFormat.FULL_DATE_NO_WEEK)
        }
      },
      subTitle: {
        get () {
          if (this.pageTitle == null || this.pageTitle === '') {
            return '';
          } else {
            return ' - ' + this.pageTitle;
          }
        }
      }
    },
    methods: {
      // childByValue: function (childValue) {
        // childValue就是子组件传过来的值
        // this.name = childValue
        // console.log("4444444444444444444444444444"+childValue)
      // },

      goto: function (url) {
        window.open(url, '_blank');
      },
      companyInfo: function () {
        window.open('http://www.hsgrjt.com', '_blank');
      },
      selectMenu: function (menuItem, title) {
        // console.log(menuItem)
        this.menuActive = menuItem;
        this.pageTitle = title;
      },
      menuSelected: function () {
        if (this.menuClass === 'menu-show') {
          this.menuClass = 'menu'
        }
      },
      showHideMenu: function () {
        if (this.menuClass === 'menu-show') {
          this.menuClass = 'menu'
        } else {
          this.menuClass = 'menu-show'
        }
      },
      logout: function () {
        clientUtils.logout();
        this.$router.push('/login');
      }
    },
    mounted() {
      // this.childByValue
      // console.log("12")
      this.userInfo = clientUtils.getLocalUserInfo();
      if (this.userInfo) {
        console.log(this.userInfo)

      } else {
        clientUtils.loginTimeout('');
      }

      const that = this;
      clientUtils.get("/system/ihsFile/selectPage?current=1&size=1&id="+ clientUtils.getLocalUserInfo().id+ '&type=通讯录',function (res) {
        if (res.data!=null){
          that.contactUrl ="https://"+res.data.data.records[0].url;
          console.log("通讯录:"+res.data.data)
        }
      })

      clientUtils.get("/system/ihsFile/selectPage?current=1&size=1&id="+ clientUtils.getLocalUserInfo().id+ '&type=岗位构成',function (res) {
        if (res.data!=null){
          that.dutyUrl ="https://"+res.data.data.records[0].url;
          console.log("通讯录:"+res.data.data)
        }
      })

    },
    created() {
      let that = this;
      bus.$on('son',childValue=>{
        that.name = childValue
        console.log(childValue+"++++++++++++++++++++++++++++"+that.name)
      });
    }
  }
</script>

<style scoped>
  .topShortCut {
    margin: 0 10px;
  }

  .fa {
    width: 30px;
  }

  .header {
    overflow: hidden;
    width: 100%;
    height: 55px;
    /*float: right;*/
    color: #2D3C50;
    line-height: 55px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    z-index: 1000;
  }
  .bgcb{
    background-color: #ffffff;

  }
  .bgc{
    background-color: #494a4e;
  }


  .main {
    height: calc(100vh - 80px);
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .footer {
    height: 25px;
    line-height: 25px;
    background-color: #2D3C50;
    color: #dddddd;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    z-index: 1000;
  }

  .menu-show {
    float: left;
    height: calc(100vh - 80px);
    position: absolute;
    z-index: 999;
  }

  @media screen and ( min-width: 768px) {
    .content {
      width: calc(100vw - 64px);
      height: calc(100vh - 80px);
      float: left;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .in-workbench {
      width: calc(100% - 40px);
      margin: 20px 0 0 20px;
    }

    .menu {
      float: left;
      height: calc(100vh - 80px);
    }
  }

  @media screen and ( max-width: 768px) {
    .content {
      width: calc(100vw - 0px);
      height: calc(100vh - 80px);
      float: left;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .in-workbench {
      width: calc(100% - 40px);
      margin: 20px 0 0 20px;
    }

    .menu {
      float: left;
      height: calc(100vh - 80px);
      margin-left: -600px;
    }
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element'
import axios from 'axios'
import QS from 'qs'
import md5 from 'js-md5'
import VueCookies from 'vue-cookies'
import Base64 from 'js-base64'

import clientUtils from './js/clientUtils'

const hsApi = axios.create({
  // baseURL: 'http://192.168.1.10:8160/', // 内网服务器
  // baseURL: 'http://api.mishowme.com:8160/', // 上线
  // baseURL: 'http://localhost:8160/', // 本机
  baseURL: 'http://39.103.237.127:8160/',
  withCredentials: true,
  timeout: 40000    //请求超时时间
});
hsApi.defaults.withCredentials = true;
hsApi.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';

hsApi.interceptors.request.use(function (config) {
  // 向请求头加入User-Token
  config.withCredentials = true;
  const tokenUserInfo = localStorage.userToken;
  if (tokenUserInfo != null) {
    config.headers['User-Token'] = tokenUserInfo;
    if (localStorage.ucm != null) {
      const ucmId = clientUtils.decryptLocalStorage(localStorage.ucm).UcmId;
      if (ucmId != null && ucmId > 0) {
        // config.headers['Ucm-Id'] = ucmId
        config.headers['User-Token'] = tokenUserInfo + ucmId;
      }
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = QS;
Vue.prototype.$md5 = md5;
Vue.prototype.$utils = clientUtils;
// Vue.prototype.$swtApi = swtApi
Vue.prototype.$hsApi = hsApi;
Vue.prototype.$Base64 = Base64.Base64;
Vue.use(VueCookies);
// 全局配置
Vue.prototype.$AppId = 'HS_IHS_PC';

const vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

export default vue

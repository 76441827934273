import globalConfiguration from './globalConfiguration'
// import Permission from './permissions'
import vue from '../main'
import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt/bin/jsencrypt'
import CryptoJS from 'crypto-js'
import enums from './enums'
import systemNames from "@/js/systemNames";
import api from "./api";

export const Tip = '提示';
export const NetworkTimeOut = '网络超时';
export const Acknowledged = '我知道了';
export let OverallUserId ="";
export let staticflag =true;
// 日期时间处理方法开始

export function parseDate(jsonDatetime) {
  const regexp = '([0-9]{4})(-([0-9]{2})(-([0-9]{2})(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\\.([0-9]+))?)?(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?';
  if (jsonDatetime) {
    const d = jsonDatetime.match(new RegExp(regexp));
    const date = new Date(d[1], 0, 1);

    if (d[3]) {
      date.setMonth(d[3] - 1)
    }
    if (d[5]) {
      date.setDate(d[5])
    }
    if (d[7]) {
      date.setHours(d[7])
    }
    if (d[8]) {
      date.setMinutes(d[8])
    }
    if (d[10]) {
      date.setSeconds(d[10])
    }
    if (d[12]) {
      date.setMilliseconds(Number('0.' + d[12]) * 1000)
    }
    let offset = 0;
    if (d[14]) {
      offset = (Number(d[16]) * 60) + Number(d[17]);
      offset *= ((d[15] === '-') ? 1 : -1)
    }
    offset -= date.getTimezoneOffset();
    return new Date(Number(date) + (offset * 60 * 1000))
    // return date
  } else {
    return null
  }
}
//数组去重
 export function unique(arr){
  for(var i=0; i<arr.length; i++){
    for(var j=i+1; j<arr.length; j++){
      if(arr[i]==arr[j]){         //第一个等同于第二个，splice方法删除第二个
        arr.splice(j,1);
        j--;
      }
    }
  }
  return arr;
}
// 全局日期时间格式化入口方法，防无限递归
export function formatDatetime(datetime, format) {
  if (datetime instanceof Date) {
    switch (format) {
      case enums.datetimeFormat.YEAR_MINUTE_CHS:
        return datetimeToDateChs(datetime, true) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_MINUTE_CHS:
        return datetimeToDateChs(datetime, false) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_SECOND_CHS:
        return datetimeToDateChs(datetime, false) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, true);
      case enums.datetimeFormat.FULL:
        return datetimeToDate(datetime, true) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, true);
      case enums.datetimeFormat.YEAR_MINUTE:
        return datetimeToDate(datetime, true) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_MINUTE:
        return datetimeToDate(datetime, false) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_SECOND:
        return datetimeToDate(datetime, false) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, true);
      case enums.datetimeFormat.FULL_NO_WEEK:
        return datetimeToDate(datetime, true) + ' ' + datetimeToTime(datetime, true);
      case enums.datetimeFormat.YEAR_MINUTE_NO_WEEK:
        return datetimeToDate(datetime, true) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_MINUTE_NO_WEEK:
        return datetimeToDate(datetime, false) + ' ' + datetimeToTime(datetime, false);
      case enums.datetimeFormat.MONTH_SECOND_NO_WEEK:
        return datetimeToDate(datetime, false) + ' ' + datetimeToTime(datetime, true);
      case enums.datetimeFormat.FULL_DATE_CHS:
        return datetimeToDateChs(datetime, true) + datetimeToWeek(datetime, true);
      case enums.datetimeFormat.FULL_DATE_CHS_NO_WEEK:
        return datetimeToDateChs(datetime, true);
      case enums.datetimeFormat.FULL_DATE:
        return datetimeToDate(datetime, true) + datetimeToWeek(datetime, true);
      case enums.datetimeFormat.FULL_DATE_NO_WEEK:
        return datetimeToDate(datetime, true);
      case enums.datetimeFormat.TIME_WITH_SECOND:
        return datetimeToTime(datetime, true);
      case enums.datetimeFormat.TIME_NO_SECOND:
        return datetimeToTime(datetime, false);
      default: // 默认等同于FULL_CHS
        return datetimeToDateChs(datetime, true) + datetimeToWeek(datetime, true) + ' ' + datetimeToTime(datetime, true)
    }
  } else {
    const dt = parseDate(datetime);
    if (dt instanceof Date) {
      return formatDatetime(dt, format)
    } else {
      return 'ERROR'
    }
  }
}

// [XXXX年]X月X日，防无限递归
export function datetimeToDateChs(datetime, withYear) {
  if (datetime instanceof Date) {
    let result = '';
    if (withYear) {
      result += datetime.getFullYear();
      result += '年'
    }
    result += (datetime.getMonth() + 1);
    result += '月';
    result += datetime.getDate();
    result += '日';
    return result
  } else {
    const dt = parseDate(datetime);
    if (dt instanceof Date) {
      return datetimeToDateChs(dt, withYear)
    } else {
      return '1900年1月1日'
    }
  }
}

// [XXXX-]X-X，防无限递归
export function datetimeToDate(datetime, withYear) {
  if (datetime instanceof Date) {
    let result = '';
    if (withYear) {
      result += datetime.getFullYear();
      result += '-'
    }
    result += (datetime.getMonth() + 1);
    result += '-';
    result += datetime.getDate();
    return result
  } else {
    const dt = parseDate(datetime);
    if (dt instanceof Date) {
      return datetimeToDate(dt, withYear)
    } else {
      return '1900-1-1'
    }
  }
}

function dayOfWeekCn(day) {
  switch (day) {
    case 1:
      return '一';
    case 2:
      return '二';
    case 3:
      return '三';
    case 4:
      return '四';
    case 5:
      return '五';
    case 6:
      return '六';
    default:
      return '日'
  }
}

export function datetimeToWeek(datetime, withParentheses) {
  if (datetime instanceof Date) {
    let result = '周';
    result += dayOfWeekCn(datetime.getDay());
    if (withParentheses) {
      return '(' + result + ')'
    } else {
      return result
    }
  } else {
    const dt = parseDate(datetime);
    if (dt instanceof Date) {
      return datetimeToWeek(dt, withParentheses)
    } else {
      return '00:00'
    }
  }
}

// 获取时间部分字符串表示 HH:mm[:ss]，防无限递归
export function datetimeToTime(datetime, withSecond) {
  if (datetime instanceof Date) {
    let result = datetime.getHours() < 10 ? '0' + datetime.getHours() : datetime.getHours();
    result += ':';
    result += datetime.getMinutes() < 10 ? '0' + datetime.getMinutes() : datetime.getMinutes();
    if (withSecond) {
      result += ':';
      result += datetime.getSeconds() < 10 ? '0' + datetime.getSeconds() : datetime.getSeconds()
    }
    return result
  } else {
    const dt = parseDate(datetime);
    if (dt instanceof Date) {
      return datetimeToTime(dt, withSecond)
    } else {
      return '00:00'
    }
  }
}

export function halfAnHourFromNow() {
  const now = new Date();
  const result = new Date(now + 1000 * 60 * 40);
  return result.getHours() < 10 ? '0' + result.getHours() : result.getHours() + ':' + Math.floor(result.getMinutes() / 10) + '0'
}

// 从指定时间开始到现在的时间，防无限递归
export function timeSpanFromNow(time) {
  if (time instanceof Date) {
    const endTime = new Date();
    const usedTime = endTime - time;
    const hours = Math.floor(usedTime / (3600 * 1000));
    const level = usedTime % (3600 * 1000);
    let minutes = Math.floor(level / 60000);
    const level2 = level % 60000;
    let seconds = Math.floor(level2 / 1000);
    if (minutes >= 0) {
      minutes = minutes < 10 ? '0' + minutes : minutes + ''
    } else {
      minutes = '00'
    }
    if (seconds >= 0) {
      seconds = seconds < 10 ? '0' + seconds : seconds + ''
    } else {
      seconds = '00'
    }
    if (hours > 0) {
      return hours + ':' + minutes + ':' + seconds
    } else {
      return minutes + ':' + seconds
    }
  } else {
    const datetime = parseDate(time);
    if (time instanceof Date) {
      return timeSpanFromNow(datetime)
    } else {
      return '00:00'
    }
  }
}

// 日期时间处理方法结束，数据校验方法开始

export function checkMobileNum(mobile) {
  if (mobile == null) {
    return false
  } else {
    if (mobile.length !== 11 || isNaN(mobile)) {
      return false
    } else {
      return mobile.startsWith('1')
    }
  }
}

// 数据校验方法结束，用户登录信息处理开始

export function logout () {
  const user = getLocalUserInfo();
  if (user != null) {
    vue.$hsApi.get(api.LOGOUT + user.id).then(() => {
    }).catch(function () {
      // networkErrorAlert(vue)
    }).finally(function () {
      localStorage.removeItem(systemNames.localStorageUserToken);
      localStorage.removeItem(systemNames.localStorageUserInfo);
    });
  }
}

export function loginSuccess (user) {
  localStorage.setItem(systemNames.localStorageUserToken, user.password);
  localStorage.setItem(systemNames.localStorageUserInfo, encryptLocalStorage(user));
}

// 用户登录信息处理结束，用户交互开始，各种弹框，弹消息

function popMessage(message, type) {
  vue.$message({
    showClose: true,
    message: message,
    type: type,
    offset: 100
  })
}

export function popErrorMessage(message) {
  popMessage(message, 'error')
}

export function popWarningMessage(message) {
  popMessage(message, 'warning')
}

export function popInfoMessage(message) {
  popMessage(message, 'info')
}

export function popSuccessMessage(message) {
  popMessage(message, 'success')
}

export function alert(message) {
  vue.$alert(message, Tip, {
    confirmButtonText: Acknowledged,
    dangerouslyUseHTMLString: true
  })
}

export function alertAndRedirect(message, pushRouter) {
  vue.$alert(message, Tip, {
    confirmButtonText: Acknowledged,
    dangerouslyUseHTMLString: true
  });
  vue.$router.push(pushRouter)
}

export function networkErrorAlert() {
  vue.$alert('网络连接超时，请检查网络或稍后重试', NetworkTimeOut, {
    confirmButtonText: Acknowledged
  })
}

export function unknownErrorAlert(errorMsg) {
  vue.$alert('发生了未知错误，请与客服联系！<br />错误信息：' + errorMsg, Tip, {
    confirmButtonText: Acknowledged,
    dangerouslyUseHTMLString: true
  })
}

export function loginTimeout(message) {
  if (message == null || message === '') {
    alertAndRedirect('登录失败，用户名或密码有误！', '/login')
  } else {
    alertAndRedirect(message, '/login')
  }
}

export function noPagePermissionAlert() {
  vue.$confirm('您没有权限查看此页面，如需查看，请与门店管理员联系！', Tip, {
    confirmButtonText: '返回首页',
    confirmButtonClass: 'primary',
    cancelButtonText: '重新登录',
    cancelButtonClass: 'danger',
    type: 'warning'
  }).then(() => {
    vue.$router.push('/default')
  }).catch(() => {
    vue.$router.push('/login')
  })
}

function procCommonAccessError(res) {
  if (res.data.msg != null && res.data.msg === 'login_fail') {
    // 登录信息无效
    loginTimeout();
    return false
  } else if (res.data.msg != null && res.data.msg === 'club_not_select') {
    // 没有选择门店，也打回登录页
    alertAndRedirect('此操作需要您选择门店，请重新登录后选择您要操作的门店！', '/login');
    return false
  } else if (res.data.msg != null && res.data.msg === 'access_denied') {
    // 没有权限
    alert('权限不足，请与您的门店管理员联系！');
    return false
  } else {
    // 交给前端处理
    return true
  }
}

// 用户交互，各种弹框，弹消息结束，本地存储操作开始

function getLocalInfo(localStorageName) {
  let localInfo = null;
  try {
    localInfo = decryptLocalStorage(localStorage.getItem(localStorageName))
  } catch {
    localInfo = null
  }
  return localInfo
}

// function setLocalInfo(localStorageName, info) {
//   let localInfo = null
//   try {
//     localInfo = encryptLocalStorage(info)
//     localStorage.setItem(localStorageName, localInfo)
//   } catch {
//     localInfo = null
//   }
// }

export function getLocalUserInfo() {
  return getLocalInfo(systemNames.localStorageUserInfo)
}

export function isLogin() {
  const userInfo = getLocalUserInfo();
  if (userInfo == null || userInfo.nickname == null) {
    loginTimeout();
    return false
  } else {
    return true
  }
}

// export function hasPermission (permission) {
//   // return true
//   const ucm = getLocalUcm()
//   if (ucm.permission == null) {
//     return false
//   } else {
//     return ucm.permission.indexOf('|' + permission + '|') >= 0
//   }
// }

// 本地存储操作结束，API调用开始

function debug(data) {
  console.log(data)
}

export function get(url, success) {
  const successCall = success;
  vue.$hsApi.get(url).then((res) => {
    if (procCommonAccessError(res)) {
      successCall(res);
      debug(res.data)
    }
  }).catch(function (error) {
    // 调用失败
    debug(error);
    // popErrorMessage('网络连接超时，请检查网络或稍后重试')
  })
}

export function post(url, parameters, success) {
  const successCall = success;
  vue.$hsApi.post(url, parameters).then((res) => {
    if (procCommonAccessError(res)) {
      successCall(res);
      debug(res.data)
    }
  }).catch(function (error) {
    // 调用失败
    debug(error);
    popErrorMessage('网络连接超时，请检查网络或稍后重试')
  })
}

// API调用方法结束，字符串处理方法开始

export function arrayToCommaString(array) {
  if (array == null || array.length === 0) {
    return ''
  } else {
    let result = '';
    for (let i = 0; i < array.length - 1; i++) {
      result += array[i];
      result += ','
    }
    result += array[array.length - 1];
    return result
  }
}

export function commaStringToArray(str) {
  if (str == null || str === '') {
    return []
  } else {
    return str.split(',')
  }
}

export function selectedLabelsToCommaString(elSelectRef) {
  let result = '';
  for (let i = 0; i < elSelectRef.selected.length - 1; i++) {
    result += elSelectRef.selected[i].label;
    result += ','
  }
  if (elSelectRef.selected.length > 0) {
    result += elSelectRef.selected[elSelectRef.selected.length - 1].label
  }
  return result
}

// 加密

export function encryptMd5(password) {
  return md5(password)
}

export function encryptPassword(password) {
  const temp = 'j8fHw10cM' + md5(password) + 'l0Uyc7Ew4JPhsRZALwGelipSBzq4a1nyxc2JF3Kds';
  return md5(temp)
}

export function encryptPasswordWithTimestamp(password) {
  const temp = this.timestamp() + 'k4xQPk8Tr' + md5(password) + '6d2e707bJPhsRZALwGelipSBzq4a1nyxc2JF3Kds';
  return md5(temp)
}

// 时间戳

export function timestamp() {
  const utc = new Date();
  const temp = (utc.getUTCDate() + 16) + '0' + Math.floor(utc.getUTCMinutes() / 10) + '' + (utc.getUTCFullYear() - 1376) + '0522' + (utc.getUTCMonth() + 51) + '0918' + (utc.getUTCHours() + 35) + '';
  // console.log(temp)
  return window.btoa(temp)
}

export function encryptSession(info) {
  const encryptor = new JSEncrypt();
  const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh+eNNu9LT74xZ5Tw8rI/kY3QI
    bwkR28M01bRHjR/opodN9qfC1y7XzuhWuexGmLUOu0wa+7mpzQfnY65pNTGF6lfB
    YirkBMBLVm6iqIDTJkTzz7L5n8GXKn0E8KOO2zsoVq6RK7cM4dGka73GkhMSHMC3
    YMyzvKMfWY9VoBB8CwIDAQAB`;
  encryptor.setKey(publicKey); // 设置公钥
  const rsaPassword = encryptor.encrypt(info); // 对需要加密的数据进行加密
  return rsaPassword
}

export function encryptLocalStorage(jsonObj) {
  if (globalConfiguration.enableLocalStorageEncryption) {
    return CryptoJS.AES.encrypt(JSON.stringify(jsonObj), 'MIGfMA0GCSqGSIb3DQE2AQUA')
  } else {
    return JSON.stringify(jsonObj)
  }
}

export function decryptLocalStorage(strStorage) {
  if (globalConfiguration.enableLocalStorageEncryption) {
    return JSON.parse(CryptoJS.AES.decrypt(strStorage, 'MIGfMA0GCSqGSIb3DQE2AQUA').toString(CryptoJS.enc.Utf8))
  } else {
    return JSON.parse(strStorage)
  }
}

export default {
  Tip,
  NetworkTimeOut,
  Acknowledged,
   staticflag,
  parseDate,
  formatDatetime,
  datetimeToDateChs,
  datetimeToDate,
  datetimeToWeek,
  datetimeToTime,
  halfAnHourFromNow,
  timeSpanFromNow,
  // 日期时间处理方法结束，数据校验方法开始
  checkMobileNum,
  // 数据校验方法结束
  logout,
  loginSuccess,
  popErrorMessage,
  OverallUserId,
  popWarningMessage,
  popInfoMessage,
  popSuccessMessage,
  alert,
  alertAndRedirect,
  // processCommonError,
  networkErrorAlert,
  unknownErrorAlert,
  loginTimeout,
  noPagePermissionAlert,
  getLocalUserInfo,
  get,
  post,
  arrayToCommaString,
  commaStringToArray,
  selectedLabelsToCommaString,
  encryptMd5,
  timestamp,
  encryptPassword,
  encryptPasswordWithTimestamp,
  encryptSession,
  encryptLocalStorage,
  decryptLocalStorage
}
